import {   Image,  Grid, Segment, Table,  Header, Label, List,  Item } from "semantic-ui-react";
import {config} from '../../Config'
import { formatURL } from "../../services/urlutils";






const TalentEgamerCard = (props) => {
  console.log('RENDER TalentEgamerCard')

  const customer = props.customer

  const mywidth = 5


  
console.log('talent', customer)
  return customer ?  (

      <div>

      <Header as='h2'>
        <Image src={ `${config.url.API_BASE_URL}/customers/${customer.customerid}/image/logo`}  />  {customer.name}
      </Header>

      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='red' ribbon>
                Overview
              </Label>      


              <Table fixed>

                <Table.Row>
                  <Table.Cell>
                    <b>Country</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.country}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>Region</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.region}
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>City</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.city}
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>Date of birth</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.birthdate}
                    </Table.Cell>                    
                </Table.Row>
            </Table>
            </Segment>
          </Grid.Column>

          <Grid.Column>
          <Item>
                        <Item.Content>
                        <Item.Meta><b>About</b></Item.Meta>
                        <Item.Description>
                      {customer.history}
                      </Item.Description>
                      </Item.Content>
                      </Item>   
          </Grid.Column>


          <Grid.Column>
          <Item>
                        <Item.Content>
                        <Item.Meta><b>Achievements</b></Item.Meta>
                        <Item.Description>
                      {customer.achievements}
                      </Item.Description>
                      </Item.Content>
                      </Item>                          

          </Grid.Column>        
        </Grid.Row>

        <Grid.Row>

        <Grid.Column width={mywidth}>
          <Segment>
              <Label as='a' color='brown' ribbon>
                Product
              </Label>    


              <Table>

                <Table.Row>
                  <Table.Cell>
                  <Item>
                        <Item.Content>
                        <Item.Meta><b>Egame</b></Item.Meta>
                        <Item.Description>
                      {customer.egame}
                      </Item.Description>
                      </Item.Content>
                      </Item>                          
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                  <Item>
                        <Item.Content>
                        <Item.Meta><b>Min number of tournaments</b></Item.Meta>
                        <Item.Description>
                      {customer.tournamentsnumber}
                      </Item.Description>
                      </Item.Content>
                      </Item>                          
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                  <Item>
                        <Item.Content>
                        <Item.Meta><b>Events</b></Item.Meta>
                        <Item.Description>
                          <List>
                      {customer.myevents.map(e => <List.Item>  <List.Icon name='angle right' /> <List.Content> {e} </List.Content>  </List.Item>)}
                      </List>
                      </Item.Description>
                      </Item.Content>
                      </Item>                          
                    </Table.Cell>                    
                </Table.Row>
            </Table>
            </Segment>
          </Grid.Column>



          <Grid.Column width={mywidth}>
          <Segment>
              <Label as='a' color='yellow' ribbon>
                Audience
              </Label>    
              <Table>
                <Table.Row>
                  <Table.Cell>
                    <b>website</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.website)}>{customer.website}</a>
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>facebook</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.fb)}>{customer.fb}</a>                      
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>instagram</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.instagram)}>{customer.instagram}</a>
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>twitter</b>
                    </Table.Cell>
                    <Table.Cell>
                    <a target="_blank" rel="noreferrer" href={formatURL(customer.twitter)}>{customer.twitter}</a>
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>tiktok</b>
                    </Table.Cell>
                    <Table.Cell>                      
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.tiktok)}>{customer.tiktok}</a>
                    </Table.Cell>                    
                </Table.Row>                

                <Table.Row>
                  <Table.Cell>
                    <b>Twitch</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.twitch)}>{customer.twitch}</a>                      
                    </Table.Cell>                    
                </Table.Row>                                

                <Table.Row>
                  <Table.Cell>
                    <b>Spotify</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.spotify)}>{customer.spotify}</a>                      
                    </Table.Cell>                    
                </Table.Row>                

                <Table.Row>
                  <Table.Cell>
                    <b>itunes</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.itunes)}>{customer.itunes}</a>                      
                    </Table.Cell>                    
                </Table.Row>                

                <Table.Row>
                  <Table.Cell>
                    <b>amazon</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.amaz)}>{customer.amaz}</a>                      
                    </Table.Cell>                    
                </Table.Row>                                                
            </Table>
            </Segment>
          </Grid.Column>

          <Grid.Column width={mywidth}>
            <Segment>
              <Label as='a' color='blue' ribbon>
                Contact Details
              </Label>                  


              <Table>

                <Table.Row>
                  <Table.Cell>
                    <b>Contact Person</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactperson}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>Email</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactemail}
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>Fixed Number</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactfixednumber}
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>Mobile Number</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactmobilenumber}
                    </Table.Cell>                    
                </Table.Row>
            </Table>
            </Segment>                                 
          </Grid.Column>          

          <Grid.Column width={mywidth}>


          </Grid.Column>                    
        </Grid.Row>
      </Grid>

      </div>
  ) : <></>;
};

export default TalentEgamerCard;