import { useState, useEffect, useCallback } from "react";
import {  Table, Button, Loader, Dimmer, Header, Image, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { config } from "../../../Config";
import {authService} from "../../../services/auth.service";
import {userService} from "../../../services/user.service";
import ImageViewer from 'react-simple-image-viewer';



import OrgNeedHeadRow from "./OrgNeedHeadRow";

const OrgNeedsDetails = (props) => {
    console.log('OrgNeedsDetails')

    const publish = props.publish

    const pid = props.pid
    const nid = props.nid

    const [matches, setMatches] = useState()  
    const [loader, setLoader] = useState(false)
    const [pname, setPname] = useState()

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    useEffect(() => {
      const user = authService.getCurrentUser();
  
      if (user) {
        userService.getMyPackage(pid).then((response) =>   {
          setPname (response.data.name)
        })
      }
    }, [pid]);
  


    const openImageViewer = useCallback((index) => {
      console.log('click')
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

  
    const onFind = (e) => {
      setLoader(true)
      userService.getMyMatches(nid).then((response) => {
        
        const matches = response.data
        const photos = []
        if(matches.length > 0 && matches[0].photos)
        matches.forEach((m) => {
          photos.push(m.photos.map(p => `${config.url.API_BASE_URL}/customers/${m.customerid}/image/offer?filename=${p}`))
        })

        setMatches(response.data)
        setLoader(false)
      })
    };
  
  
    console.log('publish', matches)
    // console.log('pho', photos[0])
      
    return publish && (

        <div style={{marginLeft:'2em', marginRight:'2em'}}>

          <h3>My published need for <b>{pname}</b></h3>
    
        <Table >
              <Table.Header>
                <OrgNeedHeadRow />
              </Table.Header>
    
              <Table.Body >
              
                <Table.Row >
                  <Table.Cell  >{publish.creationDate}</Table.Cell>
                  <Table.Cell>{publish.country}</Table.Cell>                            
                  <Table.Cell>{publish.region==null?'All regions':publish.region}</Table.Cell>
                  <Table.Cell>{publish.custtype}</Table.Cell>
                  <Table.Cell>{publish.promotion}</Table.Cell>
                  <Table.Cell>{publish.promotiontool}</Table.Cell>
                  <Table.Cell>{publish.promotiontoolspec?publish.promotiontoolspec:'---'}</Table.Cell>
                  <Table.Cell>{publish.minbudget.toLocaleString('en-US')}</Table.Cell>
                  <Table.Cell>{publish.maxbudget.toLocaleString('en-US')}</Table.Cell>
                  <Table.Cell>{publish.from}</Table.Cell>              
                  <Table.Cell>{publish.to}</Table.Cell>   
                  <Table.Cell style={{color:publish.active?'green':'red'}} >{publish.active?'ACTIVE':'DISABLED'}</Table.Cell> 
                  <Table.Cell> 
                    <Button onClick={onFind} disabled={!publish.active}> Find Offers </Button>  
                  </Table.Cell>
               </Table.Row>              
              
                
                </Table.Body>
           </Table>  

           {matches || loader ? (
            <Segment compact >
                    <h4>Found {matches && matches.length} matches</h4>
                    <Table basic='very' celled collapsing>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Country</Table.HeaderCell>
                        <Table.HeaderCell>Region</Table.HeaderCell>                    
                        <Table.HeaderCell>From</Table.HeaderCell>          
                        <Table.HeaderCell>To</Table.HeaderCell>          
                        <Table.HeaderCell>Cost</Table.HeaderCell>          
                       <Table.HeaderCell>Description</Table.HeaderCell>          
                        </Table.Row>
                    </Table.Header>
                
                    <Table.Body>
                        {matches && matches.length === 0 ? 
                    <Table.Row >
                        <Table.Cell>Sorry no matches found</Table.Cell>
                    </Table.Row>
                    :
                    <></>
                    }

                    {loader ?
                    <Table.Row >
                        <Table.Cell>
                            <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                            </Dimmer>              
                        </Table.Cell>
                    </Table.Row>      
                    : <></>
                    }

                        {matches &&  matches.map((m) => 
                        <Table.Row>
                        <Table.Cell>
                            <Header as='h4' image>
                            
                            {m.logo &&  
                            <Image src={ `${config.url.API_BASE_URL}/customers/${m.customerid}/image/logo`} rounded size='mini'  />}



                            <Header.Content>
                            <Link to={`/customers/${m.customerid}`}>{m.name}</Link>

                            </Header.Content>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{m.country}</Table.Cell>                    
                        <Table.Cell>{m.region}</Table.Cell>
                        <Table.Cell>{m.from}</Table.Cell>          
                        <Table.Cell>{m.to}</Table.Cell>          
                        <Table.Cell>{m.cost}</Table.Cell>
                        <Table.Cell width={5}>{m.description} <br/>
                          <Image.Group width={2}>
                            {m.photos && m.photos.map((f,index) =>
                            
                              <>
                              <Image 
                                src={`${config.url.API_BASE_URL}/customers/${m.customerid}/image/offer?filename=${f}`} 
                                size='small' 
                                style={{cursor:'pointer'}}
                                onClick={ () => openImageViewer(index) }                                
                              />  

                              {isViewerOpen && (
                                  <ImageViewer
                                    src={m.photos.map(ph => `${config.url.API_BASE_URL}/customers/${m.customerid}/image/offer?filename=${ph}`) }
                                    currentIndex={ currentImage }
                                    disableScroll={ false }
                                    closeOnClickOutside={ true }
                                    onClose={ closeImageViewer }
                                  />
                                )}                            

                            </>
                            )}
                          </Image.Group>                        
                        </Table.Cell>                                  
                        {/* <Table.Cell> {m.favorite? <Icon color='red' name='heart outline' /> : <Icon name='heart outline' />}  </Table.Cell> */}
                        </Table.Row>
                        )}
                        </Table.Body>
                        </Table>  
                </Segment>
    )
    :(<></>)}                
        </div>        
    )
}

export default OrgNeedsDetails;